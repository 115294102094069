import React from 'react';
import { observer } from 'mobx-react';


import Notifications from '../Notifications/Notifications';
import 'url-search-params-polyfill';
import { UserContext, RootStoreContext } from '../../context';
import { BlogProvider } from '../../hooks/useBlog';
import { overrideStatusResponse } from '../../utils/overrideStatusResponse';
import GlobalNotification from '../GlobalNotification/GlobalNotification';
import Routes from '../Routes/Routes';
import FullLoader from '../Loaders/FullLoader';

import {
  checkStatus,
  checkStatusTempted,
  getActiveCampaign,
  getAnchorPortalCampaign,
  initializeGlobalSession,
} from './services';
import CampaignStore from '../../store/CampaignStore';
import PaymentFallbackStore from '../../store/PaymentFallbackStore';
import { getCookie } from '../../utils/helpers';
import { EXPIRY_TIMES_IN_MS, withCacheStorage } from '../../utils/withCache';
import { getSSORedirectUrl, getSSOSessionInfo } from '../../services/SSOServices/SSOServices';

const SSO = {
  ENABLED: 'SSO_ENABLED',
  REDIRECT_URI: 'redirect_uri',
  SESSION: 'sso_session',
  COOKIE: 'SSO_SESSION',
}


@observer
class App extends React.Component {

  static contextType = RootStoreContext;

  state = {
    user: {
      userDetails: {},
      token: '',
      features: [],
    },
    isLoaded: false,
    showCookieConsent: false,
    showPremiumUpgradeBar: false,
    userPremiumExpired: false,
    activeCampaigns: [],
    anchorPortalCampaign: {},
  };

  getRedirectUri = () => {
    if (!window.location.href.includes('localhost')) {
      return window.location.href;
    }

    return process.env.REACT_APP_NAKEDNEWS_UI;
  }

  initiateSSORedirect = async (urlParams) => {
    if (getCookie(SSO.COOKIE) || urlParams.has(SSO.SESSION)) {

      return;
    }
    const { data, error } = await getSSORedirectUrl();
    const { initiateSSORedirect, redirectUrl } = data?.data || {};
    if (!error && initiateSSORedirect && redirectUrl?.length) {

      const redirectLocation = new URL(redirectUrl);
      redirectLocation.searchParams.set(SSO.REDIRECT_URI, this.getRedirectUri());
      window.location.href = redirectLocation;
    }
  }

  handleSSOSessionRedirect = async (data, urlParams) => {
    const ssoLoginIsEnabled = data?.data?.features?.includes('SSO_ENABLED');
    if (!ssoLoginIsEnabled || data?.data?.authenticated) {
      return;
    }
    await this.getUserInfo(urlParams);
  }

  getUserInfo = async (urlParams) => {
    const sessionToken = urlParams.get(SSO.SESSION);
    const { data, error } = await getSSOSessionInfo(sessionToken);

    if (data?.data?.token?.length) {
      sessionStorage.token = data?.data?.token;
      sessionStorage.username = data?.data?.user?.username;
      window.location.href = "/";
    } else if (!data?.data?.token) {
      this.initiateSSORedirect(urlParams);
    }

    return { data, error };
  }

  checkForSSOSession = async () => {
    const { data, error } = await getSSORedirectUrl();
    const { initiateSSORedirect, redirectUrl } = data?.data || {};
    if (!initiateSSORedirect) {
      return;
    }
    if (!error && initiateSSORedirect && redirectUrl?.length) {
      const redirectLocation = new URL(redirectUrl);
      redirectLocation.searchParams.set('redirect_uri', this.getRedirectUri());
      window.location.href = redirectLocation;
    }
  }

  handleSSOSessionCheck = (data, urlParams) => {
    const ssoLoginIsEnabled = data?.data?.features?.includes('SSO_ENABLED');
    const ssoRedirect = urlParams.get('redirect_sso_session');
    if (ssoLoginIsEnabled) {
      this.checkForSSOSession(ssoRedirect);
    }
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const affiliateCode = urlParams.get('_atc');

    if (affiliateCode) {
      document.cookie = `_atc=${affiliateCode}; expires=0; path=/`;
    }
    this.handleAffiliateTrackingParameter(urlParams);
    const [statusResponse, campaignResponse, anchorCampaignResponse] = await withCacheStorage([
      { noCache: true, fn: checkStatus },
      { expiryTime: EXPIRY_TIMES_IN_MS['15_MINS'], cacheKey: 'campaignActive', fn: getActiveCampaign },
      { expiryTime: EXPIRY_TIMES_IN_MS['15_MINS'], cacheKey: 'campaignAnchorPortal', fn: getAnchorPortalCampaign },
    ]);

    const { data: { data: anchorPortalCampaign } } = anchorCampaignResponse;
    const activeCampaigns = campaignResponse?.data?.data;
    const { error, data } = statusResponse;

    if (data?.data?.authenticated) {
      sessionStorage.username = data?.data?.user?.username;
      sessionStorage.token = data?.data?.user?.token;
    }
    const temptedStatusResponse = await checkStatusTempted();
    this.handleSSOSessionRedirect(data, urlParams);

    CampaignStore.setCurrentCampaign(data?.data, activeCampaigns);
    PaymentFallbackStore.checkForPaymentFailure(urlParams, data?.data);
    const userPremiumExpired = data.data.authenticated && !data.data.user.activeSubscription;
    if (!error) {
      overrideStatusResponse(data, temptedStatusResponse);
    }
    this.setAppState(userPremiumExpired, data?.data, activeCampaigns, anchorPortalCampaign);
    this.initializeGlobalPresence(data?.data);
  }

  handleAffiliateTrackingParameter = (urlParams) => {
    const actParameter = urlParams.get('act');
    if (getCookie('affiliateCode') === actParameter) {
      return;
    }
    if (actParameter) {
      const currentDate = new Date();
      const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
      const expiryDate = new Date(currentDate.getTime() + thirtyDaysInMilliseconds);
      const expires = expiryDate.toUTCString();
      document.cookie = `affiliateCode=${actParameter}; expires=${expires}; path=/`;
    }
  };

  setAppState = (userPremiumExpired, userData, activeCampaigns, anchorPortalCampaign) => {
    this.setState({
      isLoaded: true,
      activeCampaigns,
      user: userData,
      userPremiumExpired,
      anchorPortalCampaign,
      showCookieConsent: !document.cookie.includes('notify_cookie_usage=true'),
    });
    this.context.loggedInUser = userData;
  };

  initializeGlobalPresence = async (data) => {
    const globalPresenceRes = await initializeGlobalSession(data, this.context);
    if (!globalPresenceRes?.data) {
      console.error('Error initializing global presence');
    }
  };

  closePremiumUpgradeBar = () => {
    this.setState({ showPremiumUpgradeBar: false });
  };

  acceptCookies = (event) => {
    event.preventDefault();
    document.cookie = 'notify_cookie_usage=true;max-age=2592000';
    this.setState({ showCookieConsent: false });
  };

  render() {
    const {
      user,
      showPremiumUpgradeBar,
      userPremiumExpired,
      activeCampaigns,
      anchorPortalCampaign,
    } = this.state;

    const notificationsAvailable = !(user?.user && user?.user?.account?.anchor);

    if (!this.state.isLoaded) {
      return <FullLoader />;
    }

    return (
      <>
        <UserContext.Provider value={user}>
          <BlogProvider>
            {notificationsAvailable && <Notifications user={user} />}
            <Routes
              activeCampaigns={activeCampaigns}
              anchorPortalCampaign={anchorPortalCampaign}
              closePremiumUpgradeBar={this.closePremiumUpgradeBar}
              showPremiumUpgradeBar={showPremiumUpgradeBar}
              userPremiumExpired={userPremiumExpired}
            />
            <GlobalNotification />
          </BlogProvider>
        </UserContext.Provider>
      </>
    );
  }
}

export default App;
